const AdditionalOptions = () => {
    return(
      <div id='row4Labels' className='flex text-xs font-bold'>
        <label htmlFor='options' className='cursor-pointer'>
          + Additional Options
        </label>
      </div>
    )
  }

export default AdditionalOptions